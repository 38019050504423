<template>
  <div class="order-dashboard">
    <div class="toolbar">
      <DatePicker
        :maxDate="maxDate"
        :value="startDate"
        label="Startdatum"
        @input="getData($event)"
      />
    </div>
    <div class="report">
      <v-data-table
        v-model="selected"
        :headers="reportHeaders"
        :items="reportData"
        item-key="processInstanceId"
        class="elevation-1"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        :loading="dataLoading"
        loading-text="Daten werden geladen..."
        no-data-text="Es sind keine Einträge vorhanden."
        no-results-text="Für den eingegebenen Suchbegriff konnten keine Einträge gefunden werden."
        :footer-props="{
          showFirstLastPage: true,
          showCurrentPage: true,
          'items-per-page-text': 'Zeilen pro Seite:',
          'items-per-page-options': [10, 25, 50, 100]
        }"
        :search="searchText"
        :options.sync="options"
        :server-items-length="totalDataTableCount"
        @dblclick:row="rowSelected"
      >
        <template #top>
          <v-toolbar flat>
            <v-toolbar-title> Übersicht Auftragseingänge </v-toolbar-title>
            <v-text-field
              v-model="searchText"
              append-icon="mdi-magnify"
              label="Suche"
              single-line
              hide-details
              clearable
              class="space-left"
            />
            <v-spacer></v-spacer>
            <v-btn-toggle
              v-model="stateFilter"
              color="primary"
              rounded
            >
              <v-btn value="ACTIVE">
                Prozess aktiv
              </v-btn>
              <v-btn value="INCIDENT">
                Fehler
              </v-btn>
              <v-btn value="COMPLETED">
                Abgeschlossen
              </v-btn>
            </v-btn-toggle>
            <v-spacer></v-spacer>
            <v-btn
              color="primary"
              :disabled="!reportData || reportData.length === 0"
              @click="exportProcessData()"
            >
              Export
            </v-btn>
          </v-toolbar>
        </template>
        <template #item="row">
          <tr>
            <td class="process-status">
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <div
                    :class="getStatusClass(row.item.state)"
                    v-bind="attrs"
                    v-on="on"
                  />
                </template>
                <span>{{ getStateString(row.item.state) }}</span>
              </v-tooltip>
            </td>
            <td class="process-start">
              <div>
                {{
                  new Date(row.item.dateProcessStart).toLocaleString('de-DE', {
                    year: 'numeric',
                    month: '2-digit',
                    day: '2-digit',
                    hour: '2-digit',
                    minute: '2-digit'
                  })
                }}
              </div>
            </td>
            <td>
              <div v-if="row.item.orderOrigin == 'SPOT_FORMULAR'" class="row">
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      class="icon-light"
                      v-bind="attrs"
                      v-on="on"
                    >
                      mdi-spotlight
                    </v-icon>
                    <span
                      v-bind="attrs"
                      class="icon-text"
                      v-on="on"
                    >
                      {{ row.item.userInitiator }}
                    </span>
                  </template>
                  <span>SPOT-Formular</span>
                </v-tooltip>
                <span></span>
              </div>
              <div v-if="row.item.orderOrigin == 'CUSTOMER_FORM'" class="row">
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      class="icon-light"
                      v-bind="attrs"
                      v-on="on"
                    >
                      mdi-web
                    </v-icon>
                    <span
                      v-bind="attrs"
                      class="icon-text"
                      v-on="on"
                    >
                      {{ row.item.userInitiator }}
                    </span>
                  </template>
                  <span>Online-Bestellung</span>
                </v-tooltip>
                <span></span>
              </div>
            </td>
            <td class="activity-user">
              <div
                v-for="user in row.item.currentActivity"
                :key="user.activity"
                class="icon-wrapper"
              >
                <div class="activity-col">
                  {{ user.activity }}
                </div>

                <div v-if="user.user" class="user-account">
                  <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        style="font-size: 20px; padding-left: 5px"
                        v-bind="attrs"
                        v-on="on"
                      >
                        mdi-account-outline
                      </v-icon>
                    </template>
                    <span>
                      <div>Bearbeiter: {{ user.user }}</div>
                    </span>
                  </v-tooltip>
                </div>
              </div>
            </td>
            <td>
              <div v-if="row.item.displayHouseConnectionInfo" class="row">
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      class="icon-light"
                      v-bind="attrs"
                      v-on="on"
                    >
                      mdi-home
                    </v-icon>
                    <span
                      v-bind="attrs"
                      class="icon-text"
                      v-on="on"
                    >
                      {{ row.item.houseConnectionCustomerName }}
                    </span>
                  </template>
                  <span v-if="row.item.houseConnectionCustomerAddress">
                    <div>
                      {{ row.item.houseConnectionCustomerAddress }}
                    </div>
                  </span>
                </v-tooltip>
              </div>
              <div v-if="row.item.displayServiceConnectionInfo" class="row">
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      class="icon-light"
                      v-bind="attrs"
                      v-on="on"
                    >
                      mdi-router-network
                    </v-icon>
                    <span
                      v-bind="attrs"
                      class="icon-text"
                      v-on="on"
                    >
                      {{ row.item.serviceConnectionCustomerName }}
                    </span>
                  </template>
                  <span v-if="row.item.serviceConnectionCustomerAddress">
                    <div>
                      {{ row.item.serviceConnectionCustomerAddress }}
                    </div>
                  </span>
                </v-tooltip>
              </div>
            </td>
            <td>
              <div v-if="row.item.houseConnectionContractId" class="row">
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      class="icon-light"
                      v-bind="attrs"
                      v-on="on"
                    >
                      mdi-home
                    </v-icon>
                    <span class="icon-text">
                      {{ row.item.houseConnectionContractId }}
                    </span>
                  </template>
                  <span>Hausanschlussauftrag</span>
                </v-tooltip>
              </div>
              <div v-if="row.item.serviceConnectionContractId" class="row">
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      class="icon-light"
                      v-bind="attrs"
                      v-on="on"
                    >
                      mdi-router-network
                    </v-icon>
                    <span class="icon-text">
                      {{ row.item.serviceConnectionContractId }}
                    </span>
                  </template>
                  <span>Dienstauftrag</span>
                </v-tooltip>
              </div>
            </td>
            <td>{{ row.item.referenceId }}</td>
          </tr>
        </template>
      </v-data-table>
      <v-overlay v-if="loadingExport">
        <v-progress-circular
          :size="120"
          color="primary"
          indeterminate
        >
        </v-progress-circular>
      </v-overlay>
    </div>
  </div>
</template>
<script>
import { HTTP } from '@/main/httpClient.js';
import ErrorMessageBuilder from '@/util/ErrorMessageBuilder.js';
import NotificationObject from '@/main/NotificationObject.js';
import DatePicker from '@/components/elements/DatePicker.vue';
import { LocalDate } from '@js-joda/core';
import { objectToQueryParamString } from '@/util/ObjectUtil.js';

export default {
  name: 'OrderSummaryDashboard',
  components: { DatePicker },
  data: () => ({
    stateFilter: '',
    selected: [],
    selectedItem: null,
    sortBy: 'dateProcessStart',
    sortDesc: true,
    dataLoading: false,
    startDate: null,
    loadingExport: false,
    reportHeaders: [
      {
        text: 'Status',
        value: 'state',
        sortable: true
      },
      {
        text: 'Startdatum',
        align: 'start',
        value: 'dateProcessStart',
        filterable: false,
        sortable: true
      },
      {
        text: 'Kanal / Benutzer',
        align: 'start',
        value: 'userInitiator',
        filterable: false,
        sortable: true
      },
      {
        text: 'Status des Auftrages',
        value: 'currentActivity',
        filterable: false,
        sortable: false
      },
      {
        text: 'Kunde',
        value: 'customer',
        filterable: false,
        sortable: false
      },
      {
        text: 'Vertragsnummer',
        value: 'houseConnectionContractId',
        filterable: false,
        sortable: true
      },
      {
        text: 'Referenz ID',
        value: 'referenceId',
        filterable: false,
        sortable: true
      }
    ],
    reportData: [],
    searchText: '',
    options: {},
    totalDataTableCount: 0,
    searchTimeoutId: null
  }),
  computed: {
    defaultDate() {
      const minDate = LocalDate.now().minusDays(15);
      return minDate.toString();
    },
    maxDate() {
      const maxDate = LocalDate.now();
      return maxDate.toString();
    }
  },
  watch: {
    stateFilter() {
      this.getData(this.startDate);
    },
    options: {
      handler() {
        this.getData(this.startDate);
      },
      deep: true
    },
    searchText(e) {
      if (this.searchTimeoutId) clearTimeout(this.searchTimeoutId);
      this.searchTimeoutId = setTimeout(() => {
        Object.assign(this.options, { page: 1 });
        this.getData(this.startDate);
      }, 1000);
    }
  },
  created: function () {
    this.getData(this.defaultDate);
  },
  methods: {
    getStatusClass(state) {
      let _class = '';
      switch (state) {
        case 'ACTIVE':
          _class = 'status-green';
          break;
        case 'INCIDENT':
          _class = 'status-red';
          break;
        case 'COMPLETED':
          _class = 'status-grey';
          break;
        case 'EXTERNALLY_TERMINATED':
          _class = 'status-light-grey';
          break;
        default:
          _class = 'status-unknown';
          break;
      }
      return _class;
    },
    rowSelected(item) {
      this.selectedItem = item;
    },
    getStateString(state) {
      switch (state) {
        case 'ACTIVE':
          return 'Prozess aktiv';
        case 'INCIDENT':
          return 'Fehler aufgetreten';
        case 'COMPLETED':
          return 'Prozess beendet';
        case 'EXTERNALLY_TERMINATED':
          return 'Prozess abgebrochen';
        default:
          return 'Unbekannter Zustand';
      }
    },
    getCustomerName(customerDetails) {
      if (customerDetails === null) {
        return '';
      }
      return customerDetails.firstName + ' ' + customerDetails.lastName;
    },
    getCustomerAddress(customerDetails) {
      if (customerDetails === null) {
        return '';
      }
      let houseDetails = customerDetails.houseNumber;
      if (customerDetails.houseNumberSupplement) {
        houseDetails += customerDetails.houseNumberSupplement;
      }
      return (
        customerDetails.street +
        ' ' +
        houseDetails +
        ' ' +
        customerDetails.zipCode +
        ' ' +
        customerDetails.city
      );
    },
    showMessage(type, description) {
      this.$store.commit(
        'addNotification',
        new NotificationObject(type, description)
      );
    },
    getData(startDate) {
      const { sortBy, sortDesc, page, itemsPerPage } = this.options;

      const queryObject = {
        startDate: startDate,
        sortBy: sortBy,
        sortDesc: sortDesc,
        page: page || 1,
        perPage: itemsPerPage || 10,
        search: this.searchText?.trim(),
        stateFilter: this.stateFilter
      };

      const searchQuery = objectToQueryParamString(queryObject);

      this.startDate = startDate;
      this.dataLoading = true;
      HTTP.get(`/order/report?${searchQuery}`)
        .then((result) => {
          this.reportData = this.buildDashboardData(
            result.data.processInstances
          );
          this.totalDataTableCount = result.data.total;
        })
        .catch((err) =>
          this.showMessage(
            'error',
            ErrorMessageBuilder.buildErrorMessage(
              err.response.data,
              'Daten konnten nicht geladen werden.'
            )
          )
        )
        .finally(() => {
          this.dataLoading = false;
        });
    },
    buildDashboardData(response) {
      return response.map((dataRow) => {
        const startDate = dataRow.dateProcessStart;
        const houseConnectionCustomerName = this.getCustomerName(
          dataRow.processInfo?.houseConnectionCustomerDetails
        );
        const houseConnectionCustomerAddress = this.getCustomerAddress(
          dataRow.processInfo?.houseConnectionCustomerDetails
        );
        const serviceConnectionCustomerName = this.getCustomerName(
          dataRow.processInfo?.serviceConnectionCustomerDetails
        );
        const serviceConnectionCustomerAddress = this.getCustomerAddress(
          dataRow.processInfo?.serviceConnectionCustomerDetails
        );
        const displayHouseConnectionInfo = houseConnectionCustomerName !== '';
        const displayServiceConnectionInfo =
          serviceConnectionCustomerName !== '' &&
          serviceConnectionCustomerName !== houseConnectionCustomerName;
        const customer = {
          houseConnectionDetails:
            dataRow.processInfo?.houseConnectionCustomerDetails,
          serviceConnectionDetails:
            dataRow.processInfo?.serviceConnectionCustomerDetails
        };
        return {
          currentActivity: dataRow.currentActivities?.map((ac) => {
            return ac;
          }),
          state: dataRow.state,
          dateProcessStart: startDate ? new Date(startDate) : null,
          houseConnectionContractId:
            dataRow.processInfo?.houseConnectionContractId,
          referenceId: dataRow.processInfo?.referenceId,
          serviceConnectionContractId:
            dataRow.processInfo?.serviceConnectionContractId,
          customer: customer,
          houseConnectionCustomerName: houseConnectionCustomerName,
          houseConnectionCustomerAddress: houseConnectionCustomerAddress,
          serviceConnectionCustomerName: serviceConnectionCustomerName,
          serviceConnectionCustomerAddress: serviceConnectionCustomerAddress,
          displayHouseConnectionInfo: displayHouseConnectionInfo,
          displayServiceConnectionInfo: displayServiceConnectionInfo,
          houseConnectionCustomerId:
            dataRow.processInfo?.houseConnectionCustomerId,
          serviceConnectionCustomerId:
            dataRow.processInfo?.serviceConnectionCustomerId,
          userInitiator: dataRow.userInitiator,
          orderOrigin: dataRow.processInfo?.orderOrigin
        };
      });
    },
    exportProcessData() {
      this.loadingExport = true;
      let exportDate = this.startDate;
      if (this.startDate == null) {
        exportDate = this.defaultDate;
      }
      HTTP.get(`/order/exportDashboardAsExcel?startDate=${exportDate}`, {
        responseType: 'blob'
      })
        .then((resp) => {
          const responseHeaders = resp.headers;
          const mediaType = responseHeaders['content-type'];
          const fileName = responseHeaders['content-disposition'].split('=')[1];
          const blob = new Blob([resp.data], {
            type: mediaType
          });
          const link = document.createElement('a');
          link.href = URL.createObjectURL(blob);
          link.download = fileName;
          link.click();
          URL.revokeObjectURL(link.href);
        })
        .catch((err) =>
          this.showMessage(
            'error',
            ErrorMessageBuilder.buildErrorMessage(
              err.response.data,
              'Die Daten konnten nicht exportiert werden'
            )
          )
        )
        .finally(() => {
          this.loadingExport = false;
        });
    },
    test(value) {
      console.log(value);
    }
  }
};
</script>

<style scoped lang="scss">
.order-dashboard::v-deep .activity-user {
  max-width: 350px;
}
.order-dashboard::v-deep .status-unknown {
  width: 30px;
  height: 30px;
  background-color: #6d005e;
  border-radius: 50%;
  box-shadow: 0 0 4px 1px #6d005e;
}
.order-dashboard::v-deep .status-green {
  width: 30px;
  height: 30px;
  background-color: #80ed99;
  border-radius: 50%;
  box-shadow: 0 0 4px 1px #80ed99;
}
.order-dashboard::v-deep .status-grey {
  width: 30px;
  height: 30px;
  background-color: var(--goe-fontColor-lighter);
  border-radius: 50%;
  box-shadow: 0 0 4px 1px var(--goe-fontColor-lighter);
}
div.order-dashboard::v-deep .status-red {
  width: 30px;
  height: 30px;
  background-color: #ff6b6b;
  border-radius: 50%;
  box-shadow: 0 0 4px 1px #ff6b6b;
}
div.order-dashboard::v-deep .status-light-grey {
  width: 30px;
  height: 30px;
  background-color: #dddd;
  border-radius: 50%;
  box-shadow: 0 0 4px 1px #dddd;
}
div.order-dashboard::v-deep .icon-wrapper {
  display: flex;
  .activity-col {
    flex-direction: column;
  }
}
.v-tooltip__content {
  pointer-events: auto;
}
.toolbar {
  display: grid;
  grid-template-columns: 25% 25% 200px;
  gap: 0px 10px;
  justify-items: start;
  align-items: center;
}
.report {
  padding-top: var(--goe-spacing-2);
}
.space-left {
  margin-left: 15px;
}
.icon-light {
  color: var(--goe-fontColor-highlight);
  font-size: 20px;
}
.icon-text {
  padding-top: 3px;
  padding-left: 3px;
}
</style>
