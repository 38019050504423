<template>
  <div class="popup">
    <div style="height: 500px">
      <ProcessViewerWithHighlighting
        v-if="currentActivities != null && currentIncidents != null"
        :url="'/process-definition/key/Kundenanschalten-Portierung-TelefoniePortierung/xml'"
        :currentActivities="currentActivities"
        :currentIncidents="currentIncidents"
      />
    </div>
    <CustomerDetails
      v-if="customerData !== null"
      :data="customerData"
      title="Kundeninformationen"
    />
    <div>
      <v-data-table
        v-if="activityHistory != null"
        :headers="activityHistoryHeaders"
        :items="activityHistory"
        item-key="startTime"
        class="elevation-1"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
      >
        <template v-slot:top>
          <v-toolbar flat>
            <v-toolbar-title>
              Historie vergangener Prozessschritte
            </v-toolbar-title>
            <v-spacer></v-spacer>
          </v-toolbar>
        </template>
        <template #item="{ item }">
          <v-chip v-if="item.endTime == null" :color="'green'">
          </v-chip>
        </template>
      </v-data-table>
    </div>
  </div>
</template>

<script>
import { HTTP } from '@/main/httpClient.js';
import NotificationObject from '@/main/NotificationObject.js';
import ProcessViewerWithHighlighting from './ProcessViewerWithHighlighting.vue';
import CustomerDetails from '@/components/CustomerDetails.vue';
import ErrorMessageBuilder from '@/util/ErrorMessageBuilder.js';

export default {
  name: 'PortingProcessDashboardPopup',
  components: { ProcessViewerWithHighlighting, CustomerDetails },
  props: {
    processInformation: { type: Object, required: true, default: null },
    dialogOpen: { type: Boolean, required: true, default: false }
  },
  data: () => ({
    sortBy: 'startTime',
    sortDesc: true,
    currentActivities: null,
    currentIncidents: null,
    activityHistory: null,
    customerData: null,
    activityHistoryHeaders: [
      {
        text: 'Startdatum',
        align: 'start',
        value: 'startTime'
      },
      {
        text: 'Task',
        value: 'activityName',
        sortable: false
      },
      { text: 'Activ', value: 'endTime' }
    ]
  }),
  watch: {
    dialogOpen: {
      immediate: true,
      handler(newVal, oldVal) {
        if (newVal === true) {
          this.currentActivities = null;
          this.currentIncidents = null;
          this.activityHistory = null;
          this.customerData = this.processInformation.customerDto;
          this.fetchCurrentActivities(
            this.processInformation.processInstanceId
          );
        }
      },
      deep: true
    }
  },
  methods: {
    fetchCurrentActivities: function (processInstanceId) {
      this.dataLoading = true;
      HTTP.get('/process-instance/' + processInstanceId + '/activity-instances')
        .then((result) => {
          const currentActivitiesAndIncidents =
            this.extractCurrentActivitiesAndIncidents(result.data);

          this.currentActivities =
            currentActivitiesAndIncidents.currentActivities;
          this.currentIncidents =
            currentActivitiesAndIncidents.currentIncidents;
        })
        .catch((err) =>
          this.showMessage(
            'error',
            ErrorMessageBuilder.buildErrorMessage(err.response.data, 'Daten konnten nicht geladen werden.')
          )
        )
        .finally(() => {
          this.dataLoading = false;
          this.fetchActivitiesHistory(processInstanceId);
        });
    },
    fetchActivitiesHistory: function (processInstanceId) {
      this.dataLoading = true;
      HTTP.get('/history/activity-instance', {
        params: { processInstanceId: processInstanceId }
      })
        .then((result) => {
          this.activityHistory = result.data;
        })
        .catch((err) =>
          this.showMessage(
            'error',
            ErrorMessageBuilder.buildErrorMessage(err.response.data, 'Daten konnten nicht geladen werden.')
          )
        )
        .finally(() => {
          this.dataLoading = false;
        });
    },
    extractCurrentActivitiesAndIncidents: function (currentActivities) {
      const activities = [];
      const incidents = [];
      const childActivities = currentActivities.childActivityInstances;
      for (const key in childActivities) {
        const childActivityInstance = childActivities[key];
        const activityId = childActivityInstance.activityId;
        //  Evaluate condition. Differentiating between incidents and normal activities is not working properly, yet.
        if (childActivityInstance.incidents === []) {
          activities.push(activityId);
        } else {
          incidents.push(activityId);
        }
      }
      console.log(activities);
      return {
        currentActivities: activities,
        currentIncidents: incidents
      };
    },
    showMessage: function (type, description) {
      this.$store.commit(
        'addNotification',
        new NotificationObject(type, description)
      );
    },

    rowSelected: function (event, { item }) {
      this.selectedItem = item;
    }
  }
};
</script>

<style scoped>
.popup {
  background-color: white;
}
</style>
