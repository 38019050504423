<template>
  <div class="porting-dashboard">
    <v-data-table
      v-model="selected"
      :headers="reportHeaders"
      :items="reportData"
      item-key="processInstanceId"
      class="elevation-1"
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
      :loading="dataLoading"
      loading-text="Daten werden geladen..."
      no-data-text="Es sind keine Einträge vorhanden."
      no-results-text="Für den eingegebenen Suchbegriff konnten keine Einträge gefunden werden."
      :footer-props="{
        showFirstLastPage: true,
        showCurrentPage: true,
        'items-per-page-text': 'Zeilen pro Seite:',
        'items-per-page-options': [10, 25, 50, 100]
      }"
      :server-items-length="totalDataTableCount"
      :options.sync="options"
      :search="searchText"
      @dblclick:row="rowSelected"
    >
      <template #top>
        <v-toolbar flat>
          <v-toolbar-title> Übersicht laufender {{ title }} </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            :disabled="!reportData || reportData.length === 0"
            @click="exportWithdrawalProcessData()"
          >
            Export
          </v-btn>
        </v-toolbar>
        <v-toolbar flat>
          <v-text-field
            v-model="searchText"
            append-icon="mdi-magnify"
            label="Suche"
            single-line
            hide-details
            clearable
          />
          <v-btn-toggle
            v-if="title === 'Provisionierungsprozesse'"
            v-model="toggle_exclusive"
            color="primary"
            rounded
            mandatory
          >
            <v-tooltip
              v-for="button in toggleButtons"
              :key="button.id"
              top
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  depressed
                  v-bind="attrs"
                  large
                  class="toggle-button-group v-label theme--light"
                  v-on="on"
                >
                  {{ button.text }}
                </v-btn>
              </template>
              {{ button.tooltip }}
            </v-tooltip>
          </v-btn-toggle>
        </v-toolbar>
        <v-overlay v-if="loadingExport">
          <v-progress-circular
            :size="120"
            color="primary"
            indeterminate
          >
          </v-progress-circular>
        </v-overlay>
      </template>
      <template #item="row">
        <tr>
          <td>
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <div
                  :class="getStatusClass(row.item.state)"
                  v-bind="attrs"
                  v-on="on"
                />
              </template>
              <span>{{ getStateString(row.item.state) }}</span>
            </v-tooltip>
          </td>
          <td class="process-start">
            <div>
              {{
                new Date(row.item.dateProcessStart).toLocaleString('de-DE', {
                  year: 'numeric',
                  month: '2-digit',
                  day: '2-digit',
                  hour: '2-digit',
                  minute: '2-digit'
                })
              }}
            </div>
          </td>
          <td v-if="row.item.userInitiator">
            {{ row.item.userInitiator }}
          </td>
          <td class="activity-user">
            <div
              v-for="user in row.item.currentActivity"
              :key="user.activity"
              class="icon-wrapper"
            >
              <div class="activity-col">
                {{ user.activity ? user.activity : '-' }}
              </div>

              <div v-if="user.user" class="user-account">
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      style="font-size: 20px; padding-left: 5px"
                      v-bind="attrs"
                      v-on="on"
                    >
                      mdi-account-outline
                    </v-icon>
                  </template>
                  <span>
                    <div>Bearbeiter: {{ user.user }}</div>
                  </span>
                </v-tooltip>
              </div>
            </div>
          </td>

          <td>
            {{ row.item.customerId }}
          </td>
          <td>
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <span v-bind="attrs" v-on="on">
                  {{ row.item.customerName ? row.item.customerName : '-' }}
                </span>
              </template><span v-if="row.item.customerAddress">
                <div>
                  {{ row.item.customerAddress }}
                </div>
                <div>
                  {{ row.item.customerAddress2 }}
                </div>
              </span>
              <span v-else>
                <div>Kundenandresse unbekannt</div>
              </span>
            </v-tooltip>
          </td>

          <td>
            <div class="icon-wrapper">
              {{ row.item.contractId }}
              <!-- REVIEW: numbers condition -->
              <div v-show="row.item.numbers2" class="user-account">
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      style="font-size: 20px; padding-left: 5px"
                      v-bind="attrs"
                      v-on="on"
                    >
                      mdi-phone
                    </v-icon>
                  </template>
                  <span>
                    <div v-for="number in row.item.numbers" :key="number">
                      {{ number }}
                    </div>
                  </span>
                </v-tooltip>
              </div>

              <div v-show="row.item.wbciId" class="user-account">
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      style="font-size: 20px; padding-left: 5px"
                      v-bind="attrs"
                      v-on="on"
                    >
                      mdi-file-word-box
                    </v-icon>
                  </template>
                  <span>
                    <div>
                      {{ row.item.wbciId }}
                    </div>
                  </span>
                </v-tooltip>
              </div>
            </div>
          </td>
          <td>
            <div v-show="title === 'Provisionierungsprozesse'">
              <v-tooltip v-if="row.item.provisioningInternet" top>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon v-bind="attrs" v-on="on">
                    mdi-web
                  </v-icon>
                </template>
                <span>
                  <div>
                    {{ row.item.provisioningInternet ? 'Internet' : null }}
                  </div>
                </span>
              </v-tooltip>
              <span style="margin: 0 0.5em" />
              <v-tooltip v-if="row.item.provisioningPhone" top>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon v-bind="attrs" v-on="on">
                    mdi-phone
                  </v-icon>
                </template>
                <span>
                  <div>
                    {{ row.item.provisioningPhone ? 'Telefonie' : null }}
                  </div>
                </span>
              </v-tooltip>
              <span style="margin: 0 0.5em" />
              <v-tooltip v-if="row.item.portingPhone" top>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon v-bind="attrs" v-on="on">
                    mdi-phone-forward
                  </v-icon>
                </template>
                <span>
                  <div>
                    {{ row.item.portingPhone ? 'Rufnummerportierung' : null }}
                  </div>
                </span>
              </v-tooltip>
            </div>
          </td>
          <td>
            <div v-show="row.item.provisioningDate">
              {{ formatDate(row.item.provisioningDate) }}
            </div>
          </td>
          <td>
            <div v-show="title === 'Widerrufprozesse'">
              <div v-if="row.item.recallReason">
                {{ row.item.recallReason }}
              </div>
            </div>
          </td>
          <td>
            <div v-show="row.item.recallDate">
              {{ formatDate(row.item.recallDate) }}
            </div>
          </td>
          <td>
            <div class="button-wrapper">
              <OTRSLinkButton
                :customerId="
                  row.item.customerId !== null
                    ? row.item.customerId.toString()
                    : null
                "
              />
            </div>
          </td>
        </tr>
      </template>
    </v-data-table>
    <v-dialog v-model="dialog" hide-overlay>
      <PortingProcessDashboardPopup
        :processInformation="selectedItem"
        :dialogOpen="dialog"
      />
    </v-dialog>
  </div>
</template>

<script>
import { HTTP } from '@/main/httpClient.js';
import NotificationObject from '@/main/NotificationObject.js';
import PortingProcessDashboardPopup from './PortingProcessDashboardPopup.vue';
import ProcessViewerWithHighlighting from './ProcessViewerWithHighlighting.vue';
import OCSLinkButton from '@/components/elements/OCSLinkButton.vue';
import OTRSLinkButton from '@/components/elements/OTRSLinkButton.vue';
import ErrorMessageBuilder from '@/util/ErrorMessageBuilder.js';
import { objectToQueryParamString } from '@/util/ObjectUtil.js';
import Loader from '@/components/elements/Loader.vue';
import TimeUtility from '@/util/TimeUtility.js';

export default {
  name: 'PortingProcessDashboard',
  components: {
    Loader,
    PortingProcessDashboardPopup,
    ProcessViewerWithHighlighting,
    OCSLinkButton,
    OTRSLinkButton
  },
  props: {
    title: {
      type: String,
      required: false,
      default: 'Prozesse'
    }
  },
  data: () => ({
    selected: [],
    dialog: false,
    selectedItem: null,
    sortBy: 'dateProcessStart',
    sortDesc: true,
    dataLoading: true,
    // filterable: false does not mean that the filter will not search in this field, the filter function will work on the whole item.
    // This is just so vuetify will not call the filter function multiple times for each row.
    reportData: [],
    searchText: '',
    options: {},
    totalDataTableCount: 0,
    searchTimeoutId: null,
    toggle_exclusive: undefined,
    queryObjectData: {},
    sortByMap: {
      customerId: 'customerId',
      contractId: 'contractId',
      state: 'status',
      provisioningDate: 'provisioningDate',
      datePorting: 'portingDate',
      userInitiator: 'startBy',
      dateProcessStart: 'startDate'
    },
    toggleButtons: [
      {
        id: 1,
        text: 'Alle Prozesse',
        tooltip: 'Alle gestarteten Prozesse'
      },
      {
        id: 2,
        text: 'Provisionierungstag +-2 Tage',
        tooltip:
          'Alle zu provisionierenden Auftrag der letzten und der nächsten 2 Tage'
      },
      {
        id: 3,
        text: 'Provisioniert und Aktiv',
        tooltip: 'Alle provisionierten und aktiven Aufträge'
      },
      {
        id: 4,
        text: 'Abgeschlossen',
        tooltip: 'Alle provisionierten Aufträge die abgeschlossen sind'
      }
    ],
    loadingExport: false
  }),
  computed: {
    reportHeaders() {
      return [
        {
          text: 'Status',
          value: 'state',
          sortable: true
        },
        {
          text: 'Startdatum',
          align: 'start',
          value: 'dateProcessStart',
          filterable: false,
          sortable: true
        },
        {
          text: 'Gestartet Von',
          value: 'userInitiator',
          filterable: false,
          sortable: false
        },
        {
          text: 'Aktueller Prozessschritt',
          value: 'currentActivity',
          filterable: false,
          sortable: false
        },
        {
          text: 'Kundennummer',
          value: 'customerId',
          sortable: true,
          filterable: false
        },
        {
          text: 'Kunde',
          value: 'customerName',
          filterable: false,
          sortable: false
        },
        {
          text: 'Vertragsnummer',
          value: 'contractId',
          filterable: false,
          sortable: true
        },
        this.title === 'Provisionierungsprozesse'
          ? { text: 'Dienste', sortable: false, filterable: false }
          : {},
        this.title === 'Provisionierungsprozesse'
          ? {
            text: 'Provisionierungsdatum',
            value: 'provisioningDate',
            sortable: true,
            filterable: false
          }
          : {},
        this.title === 'Widerrufprozesse'
          ? {
            text: 'Widerrufsgrund',
            value: 'recallReason',
            sortable: false,
            filterable: false
          }
          : {},
        this.title === 'Widerrufprozesse'
          ? {
            text: 'Widerrufsdatum',
            value: 'recallDate',
            sortable: false,
            filterable: false
          }
          : {},
        { text: 'Aktionen', sortable: false, filterable: false }
      ];
    }
  },
  watch: {
    toggle_exclusive(e) {
      const queryMap = this.buildSearchQueryObject(this.toggle_exclusive);
      this.queryObjectData = queryMap.query;
      Object.assign(this.options, { page: 1 }, queryMap.options);
    },
    options: {
      handler() {
        this.fetchReport();
      },
      deep: true
    },
    searchText(e) {
      if (this.searchTimeoutId) clearTimeout(this.searchTimeoutId);
      this.searchTimeoutId = setTimeout(() => {
        Object.assign(this.options, { page: 1 });
        this.fetchReport();
      }, 1000);
    }
  },
  created: function () {},
  methods: {
    getStatusClass(state) {
      let _class = '';
      switch (state) {
        case 'ACTIVE':
          _class = 'status-green';
          break;
        case 'INCIDENT':
          _class = 'status-red';
          break;
        case 'COMPLETED':
          _class = 'status-grey';
          break;
        case 'EXTERNALLY_TERMINATED':
          _class = 'status-light-grey';
          break;
        default:
          _class = 'status-unknown';
          break;
      }
      return _class;
    },
    getSortQueryParameter(sortBy, sortDesc) {
      const tmpSortBy = this.sortByMap[sortBy?.[0]] || undefined;
      const tmpSortDesc = sortDesc?.[0];
      if (tmpSortBy && tmpSortDesc != null) {
        const sortDescString = tmpSortDesc === true ? 'desc' : 'asc';
        return `${tmpSortBy}, ${sortDescString}`;
      }
      return null;
    },
    buildSearchQueryObject: function (toggleExclusive) {
      /**
       * '&sort=startDate,desc'
       */
      let queryObject = { sort: 'startDate,desc' };
      let optionObject = {
        sortBy: ['dateProcessStart'],
        sortDesc: [true]
      };
      switch (toggleExclusive) {
        case 0:
          /**
           * daystarted = null
           * dayAfter, dayBefore, search, finished = null
           * sortBy =startdate
           * '&sort=startDate,desc'
           */
          queryObject = { sort: 'startDate,desc' };
          optionObject = {
            sortBy: ['dateProcessStart'],
            sortDesc: [true]
          };
          break;
        case 1:
          /**
           * dayAfter, dayBefore= 2
           * daystarted, search, finished = null
           * sortBy = provisionDate
           * 'daysAfter=2&daysBefore=2&sort=provisioningDate,desc'
           */
          queryObject = {
            daysAfter: 2,
            daysBefore: 2,
            sort: 'provisioningDate,desc'
          };
          optionObject = {
            sortBy: ['provisioningDate'],
            sortDesc: [true]
          };
          break;
        case 2:
          /**
           * dayAfter= 0, finished =false
           * daystarted,dayBefore, search= null
           * sortBy = provsionDate ASC
           * 'daysAfter=0&finished=false&sort=provisioningDate,desc'
           */
          queryObject = {
            daysAfter: 0,
            finished: false,
            sort: 'provisioningDate,desc'
          };
          optionObject = {
            sortBy: ['provisioningDate'],
            sortDesc: [true]
          };
          break;
        case 3:
          /**
           * finished =true
           * daystarted, dayAfter, dayBefore, search= null
           * sortBy = daystarted
           * 'finished=true&sort=startDate,desc'
           */
          queryObject = { finished: true, sort: 'startDate,desc' };
          optionObject = {
            sortBy: ['dateProcessStart'],
            sortDesc: [true]
          };
          break;
      }
      return { query: queryObject, options: optionObject };
    },
    fetchReport: function () {
      const { sortBy, sortDesc, page, itemsPerPage } = this.options;

      Object.assign(this.queryObjectData, {
        search: this.searchText?.trim(),
        page: page || 1,
        pageSize: itemsPerPage || 10,
        sort: this.getSortQueryParameter(sortBy, sortDesc)
      });

      const searchQuery = objectToQueryParamString(this.queryObjectData);
      this.dataLoading = true;
      if (this.title === 'Provisionierungsprozesse') {
        HTTP.get(`/provisioning/report?${searchQuery}`)
          .then((result) => {
            this.totalDataTableCount = result.data.total;
            this.reportData = this.mapReportDataToTable(
              result.data.processInstances
            );
          })
          .catch((err) =>
            this.showMessage(
              'error',
              ErrorMessageBuilder.buildErrorMessage(
                err.response.data,
                'Daten konnten nicht geladen werden.'
              )
            )
          )
          .finally(() => {
            this.dataLoading = false;
          });
      }
      if (this.title === 'TV Provisionierungsprozesse') {
        HTTP.get(`/provisioning/tv/report?${searchQuery}`)
          .then((result) => {
            this.totalDataTableCount = result.data.total;
            this.reportData = this.mapReportDataToTable(
              result.data.processInstances
            );
          })
          .catch((err) =>
            this.showMessage(
              'error',
              ErrorMessageBuilder.buildErrorMessage(
                err.response.data,
                'Daten konnten nicht geladen werden.'
              )
            )
          )
          .finally(() => {
            this.dataLoading = false;
          });
      }
      if (this.title === 'Widerrufprozesse') {
        HTTP.get(`/contracts/withdrawal/report?${searchQuery}`)
          .then((result) => {
            this.totalDataTableCount = result.data.total;
            this.reportData = this.mapReportDataToTable(
              result.data.processInstances
            );
          })
          .catch((err) =>
            this.showMessage(
              'error',
              ErrorMessageBuilder.buildErrorMessage(
                err.response.data,
                'Daten konnten nicht geladen werden.'
              )
            )
          )
          .finally(() => {
            this.dataLoading = false;
          });
      }
    },
    mapReportDataToTable: function (reportData) {
      return reportData.map((reportElement) => {
        const customer = reportElement?.customerDto;
        const startDate = reportElement.dateProcessStart;
        const portingDate = reportElement.processInfo.datePorting;
        const provisioningDate = reportElement.processInfo.provisioningDate;
        return {
          currentActivity: reportElement.currentActivities?.map((ac) => {
            return ac;
          }),
          numbers: reportElement.processInfo?.numbers?.map((numb) => {
            return numb;
          }),
          wbciId: reportElement.processInfo?.wbciId,
          state: reportElement.state,
          businessKey: reportElement.businessKey,
          userInitiator: reportElement.userInitiator,
          dateProcessStart: startDate ? new Date(startDate) : null,
          datePorting: portingDate ? new Date(portingDate) : null,
          // customer
          customerName: customer
            ? customer.firstName + ' ' + customer.lastName
            : '',
          customerStreet: customer
            ? customer.street + ' ' + customer.houseNumber
            : '',
          customerCity: customer ? customer.zipCode + ' ' + customer.city : '',
          customerAddress: customer
            ? `${customer.street} ${customer.houseNumber}`
            : '',
          customerDto: customer ?? null,
          customerAddress2: customer
            ? `${customer.zipCode} ${customer.city}`
            : '',
          customerId: reportElement.processInfo
            ? reportElement.processInfo.customerId
            : '',
          processInstanceId: reportElement.processInstanceId,
          contractId: reportElement.processInfo
            ? reportElement.processInfo.contractId
            : '',
          provisioningInternet: reportElement.processInfo.provisioningInternet,
          provisioningPhone: reportElement.processInfo.provisioningPhone,
          portingPhone: reportElement.processInfo.portingPhone,
          provisioningDate: provisioningDate
            ? new Date(provisioningDate)
            : null,
          recallDate: reportElement.processInfo
            ? reportElement.processInfo.recallDate
            : '',
          recallReason: reportElement.processInfo
            ? reportElement.processInfo.recallReason
            : ''
        };
      });
    },
    showMessage: function (type, description) {
      this.$store.commit(
        'addNotification',
        new NotificationObject(type, description)
      );
    },
    rowSelected: function (item) {
      this.dialog = true;
      this.selectedItem = item;
    },
    getStateString(state) {
      switch (state) {
        case 'ACTIVE':
          return 'Prozess aktiv';
        case 'INCIDENT':
          return 'Fehler aufgetreten';
        case 'COMPLETED':
          return 'Prozess beendet';
        case 'EXTERNALLY_TERMINATED':
          return 'Prozess abgebrochen';
        default:
          return 'Unbekannter Zustand';
      }
    },
    formatDate(date) {
      return TimeUtility.formatLocalDate(date);
    },
    exportWithdrawalProcessData() {
      this.loadingExport = true;
      HTTP.get('/contracts/withdrawal/report/excel', {
        responseType: 'blob'
      })
        .then((resp) => {
          const responseHeaders = resp.headers;
          const mediaType = responseHeaders['content-type'];
          const fileName = responseHeaders['content-disposition'].split('=')[1];
          const blob = new Blob([resp.data], {
            type: mediaType
          });
          const link = document.createElement('a');
          link.href = URL.createObjectURL(blob);
          link.download = fileName;
          link.click();
          URL.revokeObjectURL(link.href);
        })
        .catch((err) =>
          this.showMessage(
            'error',
            ErrorMessageBuilder.buildErrorMessage(
              err.response.data,
              'Die Daten konnten nicht exportiert werden'
            )
          )
        )
        .finally(() => {
          this.loadingExport = false;
        });
    }
  }
};
</script>

<style scoped lang="scss">
.porting-dashboard::v-deep .activity-user {
  max-width: 350px;
}
.porting-dashboard::v-deep .status-unknown {
  width: 30px;
  height: 30px;
  background-color: #6d005e;
  border-radius: 50%;
  box-shadow: 0 0 4px 1px #6d005e;
}
.porting-dashboard::v-deep .status-green {
  width: 30px;
  height: 30px;
  background-color: #80ed99;
  border-radius: 50%;
  box-shadow: 0 0 4px 1px #80ed99;
}
.porting-dashboard::v-deep .status-grey {
  width: 30px;
  height: 30px;
  background-color: var(--goe-fontColor-lighter);
  border-radius: 50%;
  box-shadow: 0 0 4px 1px var(--goe-fontColor-lighter);
}
div.porting-dashboard::v-deep .status-red {
  width: 30px;
  height: 30px;
  background-color: #ff6b6b;
  border-radius: 50%;
  box-shadow: 0 0 4px 1px #ff6b6b;
}
div.porting-dashboard::v-deep .status-light-grey {
  width: 30px;
  height: 30px;
  background-color: #dddd;
  border-radius: 50%;
  box-shadow: 0 0 4px 1px #dddd;
}
div.porting-dashboard::v-deep .icon-wrapper {
  display: flex;
  .activity-col {
    flex-direction: column;
  }
}
div.porting-dashboard::v-deep .button-wrapper {
  display: flex;
  div:first-child .v-btn {
    border-bottom-right-radius: 0px;
    border-top-right-radius: 0px;
  }
  div:last-child .v-btn {
    border-bottom-left-radius: 0px;
    border-top-left-radius: 0px;
  }
  div:not(:first-child):not(:last-child) .v-btn {
    border-radius: 0px;
  }
  div:not(:first-child) .v-btn {
    border-left-style: none;
  }
  .v-btn:hover {
    background-color: var(--goe-fontColor-lighter);
  }
}
.v-tooltip__content {
  pointer-events: auto;
}
.toggle-button-group {
  height: 37px !important;
}
.v-data-table-header__icon {
  opacity: 1;
}
</style>
