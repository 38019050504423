var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"home-passed-dashboard"},[_c('v-data-table',{staticClass:"elevation-1",attrs:{"value":_vm.selected,"headers":_vm.reportHeaders,"items":_vm.reportData,"show-select":true,"checkbox-color":"primary","item-key":"processInstanceId","loading":_vm.dataLoading,"loading-text":"Daten werden geladen...","no-data-text":"Es sind keine Einträge vorhanden.","no-results-text":"Für den eingegebenen Suchbegriff konnten keine Einträge gefunden werden.","footer-props":{
      showFirstLastPage: true,
      showCurrentPage: true,
      'items-per-page-text': 'Zeilen pro Seite:',
      'items-per-page-options': [10, 20, 60, 120, 500]
    },"items-per-page":60,"custom-filter":_vm.customFilter,"search":_vm.searchText,"group-by":"project","sort-by":"","server-items-length":_vm.totalDataTableCount,"options":_vm.options},on:{"update:options":function($event){_vm.options=$event},"item-selected":function($event){return _vm.itemSelected($event)},"click:row":_vm.rowClicked,"toggle-select-all":function($event){return _vm.selectAllTriggered($event)}},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-toolbar-title',[_vm._v(" Übersicht laufender Homepassedprozesse ")]),_c('v-spacer')],1),_c('v-toolbar',{attrs:{"flat":""}},[_c('v-text-field',{staticClass:"space-left",attrs:{"append-icon":"mdi-magnify","label":"Suchen nach Projektname, Projektnummer, Kundennummer oder Vertragsnummer","single-line":"","hide-details":"","clearable":""},model:{value:(_vm.searchText),callback:function ($$v) {_vm.searchText=$$v},expression:"searchText"}})],1)]},proxy:true},{key:"group.header",fn:function(ref){
    var items = ref.items;
    var isOpen = ref.isOpen;
    var toggle = ref.toggle;
return [_c('td',{attrs:{"colspan":_vm.reportHeaders.length}},[_c('v-icon',{on:{"click":toggle}},[_vm._v(" "+_vm._s(isOpen ? 'mdi-minus' : 'mdi-plus')+" ")]),_c('span',[_vm._v("Projekt: "+_vm._s(items[0].project)+", Projektphase: "+_vm._s(items[0].projectDescription)+" ")])],1)]}}])}),(_vm.oldItemsPerPage != 10)?_c('v-progress-linear',{attrs:{"indeterminate":"","active":_vm.dataLoading}}):_vm._e(),_c('div',{staticClass:"button-wrapper"},[_c('div',{staticClass:"text"},[_c('v-dialog',{attrs:{"max-width":"1000px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
    var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"disabled":_vm.selected.length === 0,"color":"primary"}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-check-circle ")]),_vm._v(" Akzeptieren ")],1)]}}]),model:{value:(_vm.dialogAccept),callback:function ($$v) {_vm.dialogAccept=$$v},expression:"dialogAccept"}},[_c('v-card',[_c('v-card-title',[_c('span',{staticClass:"text-h5"},[_vm._v("Projektstatus für diese Adressen akzeptieren?")])]),_c('v-card-text',[_c('v-container',[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.reportHeaders,"items":_vm.selected,"item-key":"processInstanceId","sort-by":_vm.sortBy,"sort-desc":_vm.sortDesc,"footer-props":{
                  showFirstLastPage: true,
                  showCurrentPage: true,
                  'items-per-page-text': 'Zeilen pro Seite:',
                  'items-per-page-options': [10, 25, 50, 100]
                }},on:{"update:sortBy":function($event){_vm.sortBy=$event},"update:sort-by":function($event){_vm.sortBy=$event},"update:sortDesc":function($event){_vm.sortDesc=$event},"update:sort-desc":function($event){_vm.sortDesc=$event}}}),_c('div',{staticClass:"button-wrapper"},[_c('v-btn',{attrs:{"disabled":_vm.selected.length === 0,"color":"green","text":""},on:{"click":function($event){return _vm.execute(_vm.selected, 'accept')}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-check-circle ")]),_vm._v(" Akzeptieren ")],1),_c('v-btn',{attrs:{"color":"red","text":""},on:{"click":_vm.closeAcceptDialog}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v(" mdi-cancel ")]),_vm._v(" Abbrechen ")],1)],1)],1)],1)],1)],1)],1),_c('div',{staticClass:"text"},[_c('v-dialog',{attrs:{"max-width":"1000px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
                var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"disabled":_vm.selected.length === 0,"color":"primary"}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-check-circle ")]),_vm._v(" Für zweiten BA vormerken ")],1)]}}]),model:{value:(_vm.dialogSaveForSecondConstructionPhase),callback:function ($$v) {_vm.dialogSaveForSecondConstructionPhase=$$v},expression:"dialogSaveForSecondConstructionPhase"}},[_c('v-card',[_c('v-card-title',[_c('span',{staticClass:"text-h5"},[_vm._v("Für zweiten BA vormerken?")])]),_c('v-card-text',[_c('v-container',[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.reportHeaders,"items":_vm.selected,"item-key":"processInstanceId","sort-by":_vm.sortBy,"sort-desc":_vm.sortDesc,"footer-props":{
                  showFirstLastPage: true,
                  showCurrentPage: true,
                  'items-per-page-text': 'Zeilen pro Seite:',
                  'items-per-page-options': [10, 25, 50, 100]
                }},on:{"update:sortBy":function($event){_vm.sortBy=$event},"update:sort-by":function($event){_vm.sortBy=$event},"update:sortDesc":function($event){_vm.sortDesc=$event},"update:sort-desc":function($event){_vm.sortDesc=$event}}}),_c('div',{staticClass:"button-wrapper"},[_c('v-btn',{attrs:{"disabled":_vm.selected.length === 0,"color":"green","text":""},on:{"click":function($event){return _vm.execute(_vm.selected, 'save-for-second-construction-phase')}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-check-circle ")]),_vm._v(" Akzeptieren ")],1),_c('v-btn',{attrs:{"color":"red","text":""},on:{"click":_vm.closeSaveForSecondConstructionPhaseDialog}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v(" mdi-cancel ")]),_vm._v(" Abbrechen ")],1)],1)],1)],1)],1)],1)],1),_c('div',{staticClass:"text"},[_c('v-dialog',{attrs:{"max-width":"1000px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
                var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"disabled":_vm.selected.length === 0,"color":"primary"}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-close-circle ")]),_vm._v(" Ablehnen ")],1)]}}]),model:{value:(_vm.dialogReject),callback:function ($$v) {_vm.dialogReject=$$v},expression:"dialogReject"}},[_c('v-card',[_c('v-card-title',[_c('span',{staticClass:"text-h5"},[_vm._v("Projektstatus für diese Adressen ablehnen?")])]),_c('v-card-text',[_c('v-container',[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.reportHeaders,"items":_vm.selected,"item-key":"processInstanceId","footer-props":{
                  showFirstLastPage: true,
                  showCurrentPage: true,
                  'items-per-page-text': 'Zeilen pro Seite:',
                  'items-per-page-options': [10, 25, 50, 100]
                }}}),_c('div',{staticClass:"button-wrapper"},[_c('v-btn',{attrs:{"disabled":_vm.selected.length === 0,"color":"green","text":""},on:{"click":function($event){return _vm.execute(_vm.selected, 'reject')}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-close-circle ")]),_vm._v(" Ablehnen ")],1),_c('v-btn',{attrs:{"color":"red","text":""},on:{"click":_vm.closeRejectDialog}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v(" mdi-cancel ")]),_vm._v(" Abbrechen ")],1)],1)],1)],1)],1)],1)],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }