var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"porting-dashboard"},[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.reportHeaders,"items":_vm.reportData,"item-key":"processInstanceId","sort-by":_vm.sortBy,"sort-desc":_vm.sortDesc,"loading":_vm.dataLoading,"loading-text":"Daten werden geladen...","no-data-text":"Es sind keine Einträge vorhanden.","no-results-text":"Für den eingegebenen Suchbegriff konnten keine Einträge gefunden werden.","footer-props":{
      showFirstLastPage: true,
      showCurrentPage: true,
      'items-per-page-text': 'Zeilen pro Seite:',
      'items-per-page-options': [10, 25, 50, 100]
    },"search":_vm.searchText,"options":_vm.options,"server-items-length":_vm.totalDataTableCount},on:{"update:sortBy":function($event){_vm.sortBy=$event},"update:sort-by":function($event){_vm.sortBy=$event},"update:sortDesc":function($event){_vm.sortDesc=$event},"update:sort-desc":function($event){_vm.sortDesc=$event},"update:options":function($event){_vm.options=$event},"dblclick:row":_vm.rowSelected},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-toolbar-title',[_vm._v(" Übersicht laufender Portierungsprozesse ")]),_c('v-spacer')],1),_c('v-toolbar',{attrs:{"flat":""}},[_c('v-toolbar',{attrs:{"flat":""}},[_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Suche","single-line":"","hide-details":"","clearable":""},model:{value:(_vm.searchText),callback:function ($$v) {_vm.searchText=$$v},expression:"searchText"}}),_c('v-btn-toggle',{attrs:{"color":"primary","rounded":"","mandatory":""},model:{value:(_vm.toggle_exclusive),callback:function ($$v) {_vm.toggle_exclusive=$$v},expression:"toggle_exclusive"}},_vm._l((_vm.toggleButtons),function(button){return _c('v-tooltip',{key:button.id,attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
    var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"toggle-button-group v-label theme--light",attrs:{"depressed":"","large":""}},'v-btn',attrs,false),on),[_vm._v(" "+_vm._s(button.text)+" ")])]}}],null,true)},[_vm._v(" "+_vm._s(button.tooltip)+" ")])}),1)],1)],1)]},proxy:true},{key:"item",fn:function(row){return [_c('tr',[_c('td',{staticClass:"process-status"},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
    var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({class:_vm.getStatusClass(row.item.state)},'div',attrs,false),on))]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.getStateString(row.item.state)))])])],1),_c('td',{staticClass:"process-start"},[_c('div',[_vm._v(" "+_vm._s(new Date(row.item.dateProcessStart).toLocaleString('de-DE', { year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit' }))+" ")])]),_c('td',[_vm._v(" "+_vm._s(row.item.userInitiator ? row.item.userInitiator : '-')+" ")]),_c('td',{staticClass:"activity-user"},_vm._l((row.item.currentActivity),function(user,i){return _c('div',{key:user.activity + i,staticClass:"icon-wrapper"},[_c('div',{staticClass:"activity-col"},[_vm._v(" "+_vm._s(user.activity)+" ")]),(user.user)?_c('div',{staticClass:"user-account"},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
    var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticStyle:{"font-size":"20px","padding-left":"5px"}},'v-icon',attrs,false),on),[_vm._v(" mdi-account-outline ")])]}}],null,true)},[_c('span',[_c('div',[_vm._v("Bearbeiter: "+_vm._s(user.user))])])])],1):_vm._e()])}),0),_c('td',[_vm._v(" "+_vm._s(row.item.customerId)+" ")]),_c('td',[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
    var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_vm._v(" "+_vm._s(row.item.customerName)+" ")])]}}],null,true)},[(row.item.customerAddress)?_c('span',[_c('div',[_vm._v(" "+_vm._s(row.item.customerAddress)+" ")]),_c('div',[_vm._v(" "+_vm._s(row.item.customerAddress2)+" ")])]):_c('span',[_c('div',[_vm._v("Kundenandresse unbekannt")])])])],1),_c('td',[_c('div',{staticClass:"icon-wrapper"},[_vm._v(" "+_vm._s(row.item.contractId)+" "),(row.item.numbers)?_c('div',{staticClass:"user-account"},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
    var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticStyle:{"font-size":"20px","padding-left":"5px"}},'v-icon',attrs,false),on),[_vm._v(" mdi-phone ")])]}}],null,true)},[_c('span',_vm._l((row.item.numbers),function(number){return _c('div',{key:number},[_vm._v(" "+_vm._s(number)+" ")])}),0)])],1):_vm._e(),(row.item.wbciId)?_c('div',{staticClass:"user-account"},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
    var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticStyle:{"font-size":"20px","padding-left":"5px"}},'v-icon',attrs,false),on),[_vm._v(" mdi-file-word-box ")])]}}],null,true)},[_c('span',[_c('div',[_vm._v(" "+_vm._s(row.item.wbciId)+" ")])])])],1):_vm._e()])]),_c('td',{staticClass:"porting-date"},[_vm._v(" "+_vm._s(new Date(row.item.datePorting).toLocaleDateString('de-DE', { year: 'numeric', month: '2-digit', day: '2-digit' }))+" "),(row.item.newDatePorting !== null)?[_c('br'),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
    var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_c('v-icon',{attrs:{"color":"info","dense":""}},[_vm._v(" mdi-calendar ")]),_vm._v(" "+_vm._s(new Date(row.item.newDatePorting).toLocaleDateString('de-DE', { year: 'numeric', month: '2-digit', day: '2-digit' }))+" ")],1)]}}],null,true)},[_c('span',[_vm._v("Ausstehende Terminverschiebung")])])]:_vm._e()],2),_c('td',[_c('div',{staticClass:"button-wrapper"},[_c('OTRSLinkButton',{attrs:{"customerId":row.item.customerId !== null
                  ? row.item.customerId.toString()
                  : null}}),_c('OCSLinkButton',{attrs:{"wbciId":row.item.wbciId}}),(_vm.isSupervisor)?_c('ModifyPortingDate',{attrs:{"rowData":row.item,"disabled":!(
                  row.item.wbciId &&
                  row.item.currentActivity &&
                  row.item.newDatePorting == null &&
                  _vm.isInWbciProcess(row.item.currentActivity)
                )}}):_vm._e(),(_vm.isSupervisor)?_c('WbciCancellation',{attrs:{"rowData":row.item,"disabled":!(
                  row.item.wbciId &&
                  row.item.currentActivity &&
                  _vm.isInWbciProcess(row.item.currentActivity)
                )}}):_vm._e()],1)])])]}}]),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}}),_c('v-dialog',{attrs:{"hide-overlay":""},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('PortingProcessDashboardPopup',{attrs:{"processInformation":_vm.selectedItem,"dialogOpen":_vm.dialog}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }