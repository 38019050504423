<template>
  <DialogButtonWithTooltip
    tooltipTop
    :triggerButtonProps="{
      small: true,
      outlined: true,
      color: 'var(--goe-fontColor-darkish)'
    }"
    smallTriggerIcon
    iconName="mdi-calendar"
    tooltipText="Portierungstermin verschieben"
    confirmButtonText="Terminverschiebung anfragen"
    :confirmButtonDisabled="!newPortingDate"
    :disabled="disabled"
    @onCancel="() => { newPortingDate = null }"
    @onAction="initUpdatePortingDate()"
  >
    <template v-slot:triggerButtonText>
      Terminverschiebung
    </template>
    <template v-slot:dialogTitle>
      Portierungstermin verschieben
    </template>
    <template v-slot:dialogText>
      <v-row class="mt-4 mb-2 mx-2">
        <DatePicker
          label="Neues Portierungsdatum"
          :disabled="disabled"
          :minDate="minPortingDate"
          :value="newPortingDate"
          disableWeekend
          disableHolidays
          @input="updatePortingDate($event)"
        />
      </v-row>
    </template>
  </DialogButtonWithTooltip>
</template>
<script>
import { HTTP } from '@/main/httpClient.js';

import DialogButtonWithTooltip from '@/components/elements/DialogButtonWithTooltip.vue';
import DatePicker from '@/components/elements/DatePicker.vue';
import NotificationObject from '@/main/NotificationObject.js';

import { LocalDate, DayOfWeek } from '@js-joda/core';

export default {
  name: 'ModifyPortingDate',
  components: {
    DialogButtonWithTooltip,
    DatePicker
  },
  props: {
    rowData: {
      type: Object,
      required: true
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data: () => ({
    newPortingDate: undefined
  }),
  computed: {
    minPortingDate() {
      let minDate = LocalDate.now().plusDays(4);
      const dayOfWeek = minDate.dayOfWeek();

      if (dayOfWeek === DayOfWeek.SATURDAY) {
        minDate = minDate.plusDays(2);
      } else if (dayOfWeek === DayOfWeek.SUNDAY) {
        minDate = minDate.plusDays(1);
      }

      return minDate.toString();
    }
  },
  methods: {
    initUpdatePortingDate: function () {
      const requestBody = {
        processInstanceId: this.rowData.processInstanceId,
        newPortingDate: this.newPortingDate,
        contractId: this.rowData.contractId,
        wbciId: this.rowData.wbciId,
        activity: 'Message_StartUpdatePortingDate'
      };

      HTTP.post('/porting/changePortingDateForPortingProcess', requestBody)
        .then(() => {
          this.$store.commit(
            'addNotification',
            new NotificationObject(
              'success',
              `[${this.rowData.wbciId}] Eine Terminverschiebung für die Portierung wurde erfolgreich gestartet!`
            )
          );
          this.cancellationStarted = true;
        })
        .catch((error) => {
          if (error.response?.status === 403) {
            this.$store.commit(
              'addNotification',
              new NotificationObject(
                'error',
                'Sie sind zum Starten der Terminverschiebung nicht berechtigt!'
              )
            );
          } else {
            this.$store.commit(
              'addNotification',
              new NotificationObject(
                'error',
                `Die Terminverschiebung konnte nicht gestartet werden: ${
                  error.response.data && typeof error.response.data === 'object'
                    ? error.response.data.message
                    : error.response.data
                }.`
              )
            );
            console.error(error.toJSON());
          }
        })
        .finally(() => {
          this.cancellationReason = '';
          this.cancellationReasonValid = false;
        });

      console.log(this.rowData, this.disabled, requestBody);
    },
    updatePortingDate: function (varValue) {
      this.newPortingDate = varValue;
      this.$emit('stateChanged', this.newPortingDate);
    }
  }
};
</script>
<style scoped>
.v-select {
  margin-top: 1em;
  flex-grow: 0;
  width: 97%;
}
.v-text-field {
  flex-grow: 0;
  width: 97%;
}
div.row {
  justify-content: center;
}
</style>
