var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('DialogButtonWithTooltip',{attrs:{"tooltipTop":"","triggerButtonProps":{
    small: true,
    outlined: true,
    color: 'var(--goe-fontColor-darkish)'
  },"smallTriggerIcon":"","iconName":"mdi-close-octagon","tooltipText":"Portierung stornieren","confirmButtonText":"Stornierung starten","confirmButtonDisabled":!_vm.cancellationReasonValid,"disabled":_vm.disabled || _vm.cancellationStarted},on:{"onCancel":function () {
      _vm.cancellationReason = '';
      _vm.cancellationReasonValid = false;
    },"onAction":function($event){return _vm.initCancellation()}},scopedSlots:_vm._u([{key:"triggerButtonText",fn:function(){return [_vm._v(" Storno ")]},proxy:true},{key:"dialogTitle",fn:function(){return [_vm._v(" Neue Stornierungsanfrage ")]},proxy:true},{key:"dialogText",fn:function(){return [_c('v-row',[_c('v-select',{attrs:{"label":"Stornierungstyp","items":_vm.cancellationTypes,"item-text":"name","item-value":"enum"},model:{value:(_vm.selectedCancellationType),callback:function ($$v) {_vm.selectedCancellationType=$$v},expression:"selectedCancellationType"}})],1),_c('v-row',[_c('v-textarea',{ref:"cancellationReasonInput",staticClass:"input-field",attrs:{"label":"Stornierungsgrund","auto-grow":"","clearable":"","autofocus":"","clear-icon":"mdi-delete","counter":"250","rules":[
          function (v) { return v == null ||
            v.length <= 250 ||
            'Der Stornierungsgrund darf maximal 250 Zeichen lang sein!'; },
          function (v) { return (v != null && v.length > 0) ||
            'Es muss ein Stornierungsgrund angegeben werden!'; }
        ],"name":"cancellationReasonInput","placeholder":"","error-messages":!_vm.cancellationReasonGiven
            ? 'Es muss ein Stornierungsgrund angegeben werden!'
            : null,"value":_vm.cancellationReason},on:{"input":function($event){_vm.cancellationReason = $event},"update:error":function($event){_vm.cancellationReasonValid = !$event && _vm.cancellationReasonGiven}}})],1)]},proxy:true}])})}
var staticRenderFns = []

export { render, staticRenderFns }