<template>
  <div ref="container" class="vue-bpmn-diagram-container"></div>
</template>

<script>
import BpmnJS from 'bpmn-js/dist/bpmn-navigated-viewer.production.min.js';
import { HTTP } from '@/main/httpClient.js';
/**
 * This is an extended version of https://github.com/bpmn-io/vue-bpmn that allows highlighting of some elements
 */
export default {
  name: 'ProcessViewerWithHighlighting',
  props: {
    url: {
      type: String,
      required: true
    },
    currentActivities: {
      type: Array,
      required: true
    },
    currentIncidents: {
      type: Array,
      required: true
    },
    options: {
      type: Object
    }
  },
  data: function () {
    return {
      diagramXML: null
    };
  },
  watch: {
    url: function (val) {
      this.$emit('loading');
      this.fetchDiagram(val);
    },
    diagramXML: function (val) {
      this.bpmnViewer.importXML(val);
    }
  },
  mounted: function () {
    var container = this.$refs.container;
    var self = this;
    var _options = Object.assign(
      {
        container: container
      },
      this.options
    );
    this.bpmnViewer = new BpmnJS(_options);
    this.bpmnViewer.on('import.done', function (event) {
      var error = event.error;
      var warnings = event.warnings;
      if (error) {
        self.$emit('error', error);
      } else {
        self.$emit('shown', warnings);
      }
      self.bpmnViewer.get('canvas').zoom('fit-viewport');
      /*
      Highlighting was done by option proposed in option 3 at the resource
      https://github.com/bpmn-io/bpmn-js-examples/blob/master/colors/README.md#option-2-color-via-bpmn-20-extension
      */
      var canvas = self.bpmnViewer.get('canvas');

      self.currentActivities.forEach((element) => {
        canvas.addMarker(element, 'highlightCurrentActivities');
      });
      self.currentIncidents.forEach((element) => {
        canvas.addMarker(element, 'highlightCurrentIncidents');
      });
    });
    if (this.url) {
      this.fetchDiagram(this.url);
    }
  },
  beforeDestroy: function () {
    this.bpmnViewer.destroy();
  },
  methods: {
    fetchDiagram: function (url) {
      var self = this;
      HTTP.get(url)
        .then(function (response) {
          self.diagramXML = response.data.bpmn20Xml;
        })
        .catch(function (err) {
          self.$emit('error', err);
        });
    }
  }
};
</script>

<style>
.vue-bpmn-diagram-container {
  height: 100%;
  width: 100%;
}

.highlightCurrentActivities:not(.djs-connection) .djs-visual > :nth-child(1) {
  fill: green !important; /* color elements as green */
}
.highlightCurrentIncidents:not(.djs-connection) .djs-visual > :nth-child(1) {
  fill: red !important; /* color elements as red */
}
</style>
