<template>
  <DialogButtonWithTooltip
    tooltipTop
    :triggerButtonProps="{
      small: true,
      outlined: true,
      color: 'var(--goe-fontColor-darkish)'
    }"
    smallTriggerIcon
    iconName="mdi-close-octagon"
    tooltipText="Portierung stornieren"
    confirmButtonText="Stornierung starten"
    :confirmButtonDisabled="!cancellationReasonValid"
    :disabled="disabled || cancellationStarted"
    @onCancel="
      () => {
        cancellationReason = '';
        cancellationReasonValid = false;
      }
    "
    @onAction="initCancellation()"
  >
    <template v-slot:triggerButtonText>
      Storno
    </template>
    <template v-slot:dialogTitle>
      Neue Stornierungsanfrage
    </template>
    <template v-slot:dialogText>
      <v-row>
        <v-select
          v-model="selectedCancellationType"
          label="Stornierungstyp"
          :items="cancellationTypes"
          item-text="name"
          item-value="enum"
        />
      </v-row>
      <v-row>
        <v-textarea
          ref="cancellationReasonInput"
          label="Stornierungsgrund"
          class="input-field"
          auto-grow
          clearable
          autofocus
          clear-icon="mdi-delete"
          counter="250"
          :rules="[
            (v) =>
              v == null ||
              v.length <= 250 ||
              'Der Stornierungsgrund darf maximal 250 Zeichen lang sein!',
            (v) =>
              (v != null && v.length > 0) ||
              'Es muss ein Stornierungsgrund angegeben werden!'
          ]"
          name="cancellationReasonInput"
          placeholder=""
          :error-messages="
            !cancellationReasonGiven
              ? 'Es muss ein Stornierungsgrund angegeben werden!'
              : null
          "
          :value="cancellationReason"
          @input="cancellationReason = $event"
          @update:error="
            cancellationReasonValid = !$event && cancellationReasonGiven
          "
        />
      </v-row>
    </template>
  </DialogButtonWithTooltip>
</template>
<script>
import { HTTP } from '@/main/httpClient.js';
import DialogButtonWithTooltip from '@/components/elements/DialogButtonWithTooltip.vue';

import NotificationObject from '@/main/NotificationObject.js';

export default {
  name: 'WbciCancellation',
  components: {
    DialogButtonWithTooltip
  },
  props: {
    // Row-Data from the porting dashboard. Mostly needed for the WBCI ID
    rowData: {
      type: Object,
      required: true
    },
    // If true, the triggering button will be disabled.
    disabled: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data: () => ({
    cancellationReason: '',
    cancellationReasonValid: false,
    cancellationTypes: [
      { name: 'Aufhebung', enum: 'STR_AUF' },
      { name: 'Änderung', enum: 'STR_AEN' }
    ],
    selectedCancellationType: 'STR_AUF',
    cancellationStarted: false
  }),
  computed: {
    cancellationReasonGiven() {
      return (
        this.cancellationReason !== null && this.cancellationReason.length > 0
      );
    }
  },
  methods: {
    initCancellation() {
      HTTP.post('/porting/initCancellation', {
        wbciId: this.rowData.wbciId,
        cancellationReason: this.cancellationReason,
        cancellationType: this.selectedCancellationType
      })
        .then(() => {
          this.$store.commit(
            'addNotification',
            new NotificationObject(
              'success',
              `Die Stornierung des WBCI-Prozesses mit ID ${this.rowData.wbciId} wurde erfolgreich gestartet!`
            )
          );
          this.cancellationStarted = true;
        })
        .catch((error) => {
          if (error.response?.status === 403) {
            this.$store.commit(
              'addNotification',
              new NotificationObject(
                'error',
                'Die Stornierung konnte nicht gestartet werden, Sie sind zum Starten der Stornierung nicht berechtigt!'
              )
            );
          } else {
            this.$store.commit(
              'addNotification',
              new NotificationObject(
                'error',
                `Die Stornierung konnte nicht gestartet werden: ${
                  error.response.data && typeof error.response.data === 'object'
                    ? error.response.data.message
                    : error.response.data
                }.`
              )
            );
            console.error(error.toJSON());
          }
        })
        .finally(() => {
          this.cancellationReason = '';
          this.cancellationReasonValid = false;
        });
    }
  }
};
</script>
<style scoped>
.v-select {
  margin-top: 1em;
  flex-grow: 0;
  width: 97%;
}
.v-text-field {
  flex-grow: 0;
  width: 97%;
}
div.row {
  justify-content: center;
}
</style>
