var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"order-dashboard"},[_c('div',{staticClass:"toolbar"},[_c('DatePicker',{attrs:{"maxDate":_vm.maxDate,"value":_vm.startDate,"label":"Startdatum"},on:{"input":function($event){return _vm.getData($event)}}})],1),_c('div',{staticClass:"report"},[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.reportHeaders,"items":_vm.reportData,"item-key":"processInstanceId","sort-by":_vm.sortBy,"sort-desc":_vm.sortDesc,"loading":_vm.dataLoading,"loading-text":"Daten werden geladen...","no-data-text":"Es sind keine Einträge vorhanden.","no-results-text":"Für den eingegebenen Suchbegriff konnten keine Einträge gefunden werden.","footer-props":{
        showFirstLastPage: true,
        showCurrentPage: true,
        'items-per-page-text': 'Zeilen pro Seite:',
        'items-per-page-options': [10, 25, 50, 100]
      },"search":_vm.searchText,"options":_vm.options,"server-items-length":_vm.totalDataTableCount},on:{"update:sortBy":function($event){_vm.sortBy=$event},"update:sort-by":function($event){_vm.sortBy=$event},"update:sortDesc":function($event){_vm.sortDesc=$event},"update:sort-desc":function($event){_vm.sortDesc=$event},"update:options":function($event){_vm.options=$event},"dblclick:row":_vm.rowSelected},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-toolbar-title',[_vm._v(" Übersicht Auftragseingänge ")]),_c('v-text-field',{staticClass:"space-left",attrs:{"append-icon":"mdi-magnify","label":"Suche","single-line":"","hide-details":"","clearable":""},model:{value:(_vm.searchText),callback:function ($$v) {_vm.searchText=$$v},expression:"searchText"}}),_c('v-spacer'),_c('v-btn-toggle',{attrs:{"color":"primary","rounded":""},model:{value:(_vm.stateFilter),callback:function ($$v) {_vm.stateFilter=$$v},expression:"stateFilter"}},[_c('v-btn',{attrs:{"value":"ACTIVE"}},[_vm._v(" Prozess aktiv ")]),_c('v-btn',{attrs:{"value":"INCIDENT"}},[_vm._v(" Fehler ")]),_c('v-btn',{attrs:{"value":"COMPLETED"}},[_vm._v(" Abgeschlossen ")])],1),_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary","disabled":!_vm.reportData || _vm.reportData.length === 0},on:{"click":function($event){return _vm.exportProcessData()}}},[_vm._v(" Export ")])],1)]},proxy:true},{key:"item",fn:function(row){return [_c('tr',[_c('td',{staticClass:"process-status"},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
      var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({class:_vm.getStatusClass(row.item.state)},'div',attrs,false),on))]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.getStateString(row.item.state)))])])],1),_c('td',{staticClass:"process-start"},[_c('div',[_vm._v(" "+_vm._s(new Date(row.item.dateProcessStart).toLocaleString('de-DE', { year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit' }))+" ")])]),_c('td',[(row.item.orderOrigin == 'SPOT_FORMULAR')?_c('div',{staticClass:"row"},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
      var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"icon-light"},'v-icon',attrs,false),on),[_vm._v(" mdi-spotlight ")]),_c('span',_vm._g(_vm._b({staticClass:"icon-text"},'span',attrs,false),on),[_vm._v(" "+_vm._s(row.item.userInitiator)+" ")])]}}],null,true)},[_c('span',[_vm._v("SPOT-Formular")])]),_c('span')],1):_vm._e(),(row.item.orderOrigin == 'CUSTOMER_FORM')?_c('div',{staticClass:"row"},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
      var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"icon-light"},'v-icon',attrs,false),on),[_vm._v(" mdi-web ")]),_c('span',_vm._g(_vm._b({staticClass:"icon-text"},'span',attrs,false),on),[_vm._v(" "+_vm._s(row.item.userInitiator)+" ")])]}}],null,true)},[_c('span',[_vm._v("Online-Bestellung")])]),_c('span')],1):_vm._e()]),_c('td',{staticClass:"activity-user"},_vm._l((row.item.currentActivity),function(user){return _c('div',{key:user.activity,staticClass:"icon-wrapper"},[_c('div',{staticClass:"activity-col"},[_vm._v(" "+_vm._s(user.activity)+" ")]),(user.user)?_c('div',{staticClass:"user-account"},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
      var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticStyle:{"font-size":"20px","padding-left":"5px"}},'v-icon',attrs,false),on),[_vm._v(" mdi-account-outline ")])]}}],null,true)},[_c('span',[_c('div',[_vm._v("Bearbeiter: "+_vm._s(user.user))])])])],1):_vm._e()])}),0),_c('td',[(row.item.displayHouseConnectionInfo)?_c('div',{staticClass:"row"},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
      var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"icon-light"},'v-icon',attrs,false),on),[_vm._v(" mdi-home ")]),_c('span',_vm._g(_vm._b({staticClass:"icon-text"},'span',attrs,false),on),[_vm._v(" "+_vm._s(row.item.houseConnectionCustomerName)+" ")])]}}],null,true)},[(row.item.houseConnectionCustomerAddress)?_c('span',[_c('div',[_vm._v(" "+_vm._s(row.item.houseConnectionCustomerAddress)+" ")])]):_vm._e()])],1):_vm._e(),(row.item.displayServiceConnectionInfo)?_c('div',{staticClass:"row"},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
      var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"icon-light"},'v-icon',attrs,false),on),[_vm._v(" mdi-router-network ")]),_c('span',_vm._g(_vm._b({staticClass:"icon-text"},'span',attrs,false),on),[_vm._v(" "+_vm._s(row.item.serviceConnectionCustomerName)+" ")])]}}],null,true)},[(row.item.serviceConnectionCustomerAddress)?_c('span',[_c('div',[_vm._v(" "+_vm._s(row.item.serviceConnectionCustomerAddress)+" ")])]):_vm._e()])],1):_vm._e()]),_c('td',[(row.item.houseConnectionContractId)?_c('div',{staticClass:"row"},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
      var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"icon-light"},'v-icon',attrs,false),on),[_vm._v(" mdi-home ")]),_c('span',{staticClass:"icon-text"},[_vm._v(" "+_vm._s(row.item.houseConnectionContractId)+" ")])]}}],null,true)},[_c('span',[_vm._v("Hausanschlussauftrag")])])],1):_vm._e(),(row.item.serviceConnectionContractId)?_c('div',{staticClass:"row"},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
      var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"icon-light"},'v-icon',attrs,false),on),[_vm._v(" mdi-router-network ")]),_c('span',{staticClass:"icon-text"},[_vm._v(" "+_vm._s(row.item.serviceConnectionContractId)+" ")])]}}],null,true)},[_c('span',[_vm._v("Dienstauftrag")])])],1):_vm._e()]),_c('td',[_vm._v(_vm._s(row.item.referenceId))])])]}}]),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}}),(_vm.loadingExport)?_c('v-overlay',[_c('v-progress-circular',{attrs:{"size":120,"color":"primary","indeterminate":""}})],1):_vm._e()],1)])}
var staticRenderFns = []

export { render, staticRenderFns }