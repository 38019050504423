<template>
  <div class="porting-dashboard">
    <v-data-table
      v-model="selected"
      :headers="reportHeaders"
      :items="reportData"
      item-key="processInstanceId"
      class="elevation-1"
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
      :loading="dataLoading"
      loading-text="Daten werden geladen..."
      no-data-text="Es sind keine Einträge vorhanden."
      no-results-text="Für den eingegebenen Suchbegriff konnten keine Einträge gefunden werden."
      :footer-props="{
        showFirstLastPage: true,
        showCurrentPage: true,
        'items-per-page-text': 'Zeilen pro Seite:',
        'items-per-page-options': [10, 25, 50, 100]
      }"
      :search="searchText"
      :options.sync="options"
      :server-items-length="totalDataTableCount"
      @dblclick:row="rowSelected"
    >
      <template #top>
        <v-toolbar flat>
          <v-toolbar-title>
            Übersicht laufender Portierungsprozesse
          </v-toolbar-title>
          <v-spacer></v-spacer>
        </v-toolbar>
        <v-toolbar flat>
          <v-toolbar flat>
            <v-text-field
              v-model="searchText"
              append-icon="mdi-magnify"
              label="Suche"
              single-line
              hide-details
              clearable
            />
            <v-btn-toggle
              v-model="toggle_exclusive"
              color="primary"
              rounded
              mandatory
            >
              <v-tooltip
                v-for="button in toggleButtons"
                :key="button.id"
                top
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    depressed
                    v-bind="attrs"
                    large
                    class="toggle-button-group v-label theme--light"
                    v-on="on"
                  >
                    {{ button.text }}
                  </v-btn>
                </template>
                {{ button.tooltip }}
              </v-tooltip>
            </v-btn-toggle>
          </v-toolbar>
        </v-toolbar>
      </template>
      <template #item="row">
        <tr>
          <td class="process-status">
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <div
                  :class="getStatusClass(row.item.state)"
                  v-bind="attrs"
                  v-on="on"
                />
              </template>
              <span>{{ getStateString(row.item.state) }}</span>
            </v-tooltip>
          </td>
          <td class="process-start">
            <div>
              {{
                new Date(row.item.dateProcessStart).toLocaleString('de-DE', {
                  year: 'numeric',
                  month: '2-digit',
                  day: '2-digit',
                  hour: '2-digit',
                  minute: '2-digit'
                })
              }}
            </div>
          </td>
          <td>
            {{ row.item.userInitiator ? row.item.userInitiator : '-' }}
          </td>
          <td class="activity-user">
            <div
              v-for="user, i in row.item.currentActivity"
              :key="user.activity + i"
              class="icon-wrapper"
            >
              <div class="activity-col">
                {{ user.activity }}
              </div>

              <div v-if="user.user" class="user-account">
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      style="font-size: 20px; padding-left: 5px"
                      v-bind="attrs"
                      v-on="on"
                    >
                      mdi-account-outline
                    </v-icon>
                  </template>
                  <span>
                    <div>Bearbeiter: {{ user.user }}</div>
                  </span>
                </v-tooltip>
              </div>
            </div>
          </td>
          <td>
            {{ row.item.customerId }}
          </td>
          <td>
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <span v-bind="attrs" v-on="on">
                  {{ row.item.customerName }}
                </span>
              </template><span v-if="row.item.customerAddress">
                <div>
                  {{ row.item.customerAddress }}
                </div>
                <div>
                  {{ row.item.customerAddress2 }}
                </div>
              </span>
              <span v-else>
                <div>Kundenandresse unbekannt</div>
              </span>
            </v-tooltip>
          </td>
          <td>
            <div class="icon-wrapper">
              {{ row.item.contractId }}
              <div v-if="row.item.numbers" class="user-account">
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      style="font-size: 20px; padding-left: 5px"
                      v-bind="attrs"
                      v-on="on"
                    >
                      mdi-phone
                    </v-icon>
                  </template>
                  <span>
                    <div v-for="number in row.item.numbers" :key="number">
                      {{ number }}
                    </div>
                  </span>
                </v-tooltip>
              </div>

              <div v-if="row.item.wbciId" class="user-account">
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      style="font-size: 20px; padding-left: 5px"
                      v-bind="attrs"
                      v-on="on"
                    >
                      mdi-file-word-box
                    </v-icon>
                  </template>
                  <span>
                    <div>
                      {{ row.item.wbciId }}
                    </div>
                  </span>
                </v-tooltip>
              </div>
            </div>
          </td>
          <td class="porting-date">
            {{
              new Date(row.item.datePorting).toLocaleDateString('de-DE', {
                year: 'numeric',
                month: '2-digit',
                day: '2-digit'
              })
            }}
            <template v-if="row.item.newDatePorting !== null">
              <br />
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <span
                    v-bind="attrs"
                    v-on="on"
                  >
                    <v-icon color="info" dense>
                      mdi-calendar
                    </v-icon>
                    {{
                      new Date(row.item.newDatePorting).toLocaleDateString('de-DE', {
                        year: 'numeric',
                        month: '2-digit',
                        day: '2-digit'
                      })
                    }}
                  </span>
                </template>
                <span>Ausstehende Terminverschiebung</span>
              </v-tooltip>
            </template>
          </td>
          <td>
            <div class="button-wrapper">
              <OTRSLinkButton
                :customerId="
                  row.item.customerId !== null
                    ? row.item.customerId.toString()
                    : null
                "
              />
              <OCSLinkButton :wbciId="row.item.wbciId" />
              <ModifyPortingDate
                v-if="isSupervisor"
                :rowData="row.item"
                :disabled="
                  !(
                    row.item.wbciId &&
                    row.item.currentActivity &&
                    row.item.newDatePorting == null &&
                    isInWbciProcess(row.item.currentActivity)
                  )
                "
              />
              <WbciCancellation
                v-if="isSupervisor"
                :rowData="row.item"
                :disabled="
                  !(
                    row.item.wbciId &&
                    row.item.currentActivity &&
                    isInWbciProcess(row.item.currentActivity)
                  )
                "
              />
            </div>
          </td>
        </tr>
      </template>
    </v-data-table>
    <v-dialog v-model="dialog" hide-overlay>
      <PortingProcessDashboardPopup
        :processInformation="selectedItem"
        :dialogOpen="dialog"
      />
    </v-dialog>
  </div>
</template>

<script>
import { HTTP } from '@/main/httpClient.js';
import NotificationObject from '@/main/NotificationObject.js';
import PortingProcessDashboardPopup from './PortingProcessDashboardPopup.vue';
import ProcessViewerWithHighlighting from './ProcessViewerWithHighlighting.vue';
import OCSLinkButton from '@/components/elements/OCSLinkButton.vue';
import OTRSLinkButton from '@/components/elements/OTRSLinkButton.vue';
import WbciCancellation from './WbciCancellation.vue';
import ModifyPortingDate from './ModifyPortingDate.vue';
import ErrorMessageBuilder from '@/util/ErrorMessageBuilder.js';
import { objectToQueryParamString } from '@/util/ObjectUtil.js';
import Loader from '@/components/elements/Loader.vue';
import _ from 'lodash';

export default {
  name: 'PortingProcessDashboard',
  components: {
    Loader,
    PortingProcessDashboardPopup,
    ProcessViewerWithHighlighting,
    OCSLinkButton,
    OTRSLinkButton,
    WbciCancellation,
    ModifyPortingDate
  },
  data: () => ({
    selected: [],
    dialog: false,
    selectedItem: null,
    sortBy: 'dateProcessStart',
    sortDesc: true,
    dataLoading: true,
    // filterable: false does not mean that the filter will not search in this field, the filter function will work on the whole item.
    // This is just so vuetify will not call the filter function multiple times for each row.
    reportHeaders: [
      {
        text: 'Status',
        value: 'state',
        sortable: true
      },
      {
        text: 'Startdatum',
        align: 'start',
        value: 'dateProcessStart',
        sortable: true,
        filterable: false
      },
      {
        text: 'Gestartet Von',
        value: 'userInitiator',
        sortable: false,
        filterable: false
      },
      {
        text: 'Aktueller Prozessschritt',
        value: 'currentActivity',
        sortable: false,
        filterable: false
      },
      {
        text: 'Kundennummer',
        value: 'customerId',
        sortable: true,
        filterable: false
      },
      {
        text: 'Kunde',
        value: 'customerName',
        sortable: false,
        filterable: false
      },
      {
        text: 'Vertragsnummer',
        value: 'contractId',
        sortable: true,
        filterable: false
      },
      {
        text: 'Portierungsdatum',
        value: 'datePorting',
        sortable: true,
        filterable: false
      },
      { text: 'Aktionen', sortable: false, filterable: false }
    ],
    reportData: [],
    searchText: '',
    options: {},
    totalDataTableCount: 0,
    searchTimeoutId: null,
    toggle_exclusive: undefined,
    queryObjectData: {},
    sortByMap: {
      customerId: 'customerId',
      contractId: 'contractId',
      state: 'status',
      provisioningDate: 'provisioningDate',
      datePorting: 'portingDate',
      userInitiator: 'startBy',
      dateProcessStart: 'startDate'
    },
    toggleButtons: [
      {
        id: 1,
        text: 'Alle Prozesse',
        tooltip: 'Alle gestarteten Prozesse'
      },
      {
        id: 2,
        text: 'Portierungstag +7 Tage',
        tooltip: 'Alle zu portierenden Aufträge der nächsten Woche'
      },
      {
        id: 3,
        text: 'Portiert und Aktiv',
        tooltip: 'Alle portierten Aufträge und noch aktiv'
      },
      {
        id: 4,
        text: 'Abgeschlossen',
        tooltip: 'Alle abgeschlossen Prozesse'
      }
    ]
  }),
  computed: {
    isSupervisor() {
      return this.$auth.check(['LgSpotPortierungLeitung', 'camunda-admin']);
    }
  },
  watch: {
    toggle_exclusive(newValue, oldValue) {
      const queryMap = this.buildSearchQueryObject(this.toggle_exclusive);
      this.queryObjectData = queryMap.query;
      const newObject = { ...this.options };

      Object.assign(newObject, { page: 1 }, queryMap.options);
      this.options = newObject;

      if (((oldValue === 0 || newValue === 0) && (oldValue === 3 || newValue === 3))) {
        this.fetchReport();
      }
    },
    options: {
      handler(newVar, oldVar) {
        if (!_.isEqual(oldVar, newVar)) {
          this.fetchReport();
        }
      },
      deep: true
    },
    searchText(e) {
      if (this.searchTimeoutId) clearTimeout(this.searchTimeoutId);
      this.searchTimeoutId = setTimeout(() => {
        Object.assign(this.options, { page: 1 });
        this.fetchReport();
      }, 1000);
    }
  },
  created: function () {},
  methods: {
    getStatusClass(state) {
      let _class = '';
      switch (state) {
        case 'ACTIVE':
          _class = 'status-green';
          break;
        case 'INCIDENT':
          _class = 'status-red';
          break;
        case 'COMPLETED':
          _class = 'status-grey';
          break;
        case 'EXTERNALLY_TERMINATED':
          _class = 'status-light-grey';
          break;
        default:
          _class = 'status-unknown';
          break;
      }
      return _class;
    },
    getSortQueryParameter(sortBy, sortDesc) {
      const tmpSortBy = this.sortByMap[sortBy?.[0]] || undefined;
      const tmpSortDesc = sortDesc?.[0];
      if (tmpSortBy && tmpSortDesc != null) {
        const sortDescString = tmpSortDesc === true ? 'desc' : 'asc';
        return `${tmpSortBy}, ${sortDescString}`;
      }
      return null;
    },
    buildSearchQueryObject: function (toggleExclusive) {
      /**
       * '&sort=startDate,desc'
       */
      let queryObject = { sort: 'startDate,desc' };
      let optionObject = {
        sortBy: ['dateProcessStart'],
        sortDesc: [true]
      };
      switch (toggleExclusive) {
        case 0:
          /**
           * daystarted = null
           * dayAfter, dayBefore, search, finished = null
           * sortBy =startdate
           * '&sort=startDate,desc'
           */
          queryObject = { sort: 'startDate,desc' };
          optionObject = {
            sortBy: ['dateProcessStart'],
            sortDesc: [true]
          };
          break;
        case 1:
          /**
           * dayAfter = 7
           * daystarted, search, finished = null
           * sortBy = provisionDate
           * 'daysAfter=7&finished=false&daysBefore=0&sort=portingDate,asc'
           */
          queryObject = {
            daysAfter: 7,
            finished: false,
            daysBefore: 0,
            sort: 'portingDate,asc'
          };
          optionObject = {
            sortBy: ['datePorting'],
            sortDesc: [false]
          };
          break;
        case 2:
          /**
           * dayAfter= 0, finished =false
           * daystarted, dayBefore, search= null
           * sortBy = provsionDate DESC
           * 'daysAfter=0&finished=false&sort=portingDate,desc'
           */
          queryObject = {
            daysAfter: 0,
            finished: false,
            sort: 'portingDate,desc'
          };
          optionObject = {
            sortBy: ['datePorting'],
            sortDesc: [true]
          };
          break;
        case 3:
          /**
           * finished =true
           * daystarted, dayAfter, dayBefore, search= null
           * sortBy = daystarted
           * 'finished=true&sort=startDate,desc'
           */
          queryObject = { finished: true, sort: 'startDate,desc' };
          optionObject = {
            sortBy: ['dateProcessStart'],
            sortDesc: [true]
          };
          break;
        default:
        /**
         * '&sort=startDate,desc'
         */
      }

      return { query: queryObject, options: optionObject };
    },
    fetchReport: function () {
      const { sortBy, sortDesc, page, itemsPerPage } = this.options;

      Object.assign(this.queryObjectData, {
        search: this.searchText?.trim(),
        page: page || 1,
        pageSize: itemsPerPage || 10,
        sort: this.getSortQueryParameter(sortBy, sortDesc)
      });

      const searchQuery = objectToQueryParamString(this.queryObjectData);

      this.dataLoading = true;
      HTTP.get(`/porting/report?${searchQuery}`)
        .then((result) => {
          this.totalDataTableCount = result.data.total;
          this.reportData = this.mapReportDataToTable(
            result.data.processInstances
          );
        })
        .catch((err) =>
          this.showMessage(
            'error',
            ErrorMessageBuilder.buildErrorMessage(
              err.response.data,
              'Daten konnten nicht geladen werden.'
            )
          )
        )
        .finally(() => {
          this.dataLoading = false;
        });
    },
    mapReportDataToTable: function (reportData) {
      return reportData.map((reportElement) => {
        const customer = reportElement?.customerDto;
        const startDate = reportElement.dateProcessStart;
        const portingDate = reportElement.processInfo.datePorting;
        const newPortingDate = reportElement.processInfo.newDatePorting;
        return {
          currentActivity: reportElement.currentActivities?.map((ac) => {
            return ac;
          }),
          numbers: reportElement.processInfo?.numbers?.map((numb) => {
            return numb;
          }),
          wbciId: reportElement.processInfo?.wbciId,
          state: reportElement.state,
          businessKey: reportElement.businessKey,
          userInitiator: reportElement.userInitiator,
          dateProcessStart: startDate ? new Date(startDate) : null,
          datePorting: portingDate ? new Date(portingDate) : null,
          newDatePorting: newPortingDate ? new Date(newPortingDate) : null,
          // customer
          customerName:
            customer && customer.firstName && customer.lastName
              ? customer.firstName + ' ' + customer.lastName
              : '-',
          customerStreet: customer
            ? `${customer.street} ${customer.houseNumber}${customer.houseNumberSupplement}`
            : '',
          customerCity: customer ? customer.zipCode + ' ' + customer.city : '',
          customerAddress: customer
            ? `${customer.street} ${customer.houseNumber}${
                customer.houseNumberSupplement ?? ''
              }`
            : '',
          customerDto: customer ?? null,
          customerAddress2: customer
            ? `${customer.zipCode} ${customer.city}`
            : '',
          customerId: customer ? customer.customerId : null,
          processInstanceId: reportElement.processInstanceId,
          contractId: reportElement.processInfo
            ? reportElement.processInfo.contractId
            : ''
        };
      });
    },
    showMessage: function (type, description) {
      this.$store.commit(
        'addNotification',
        new NotificationObject(type, description)
      );
    },
    rowSelected: function (item) {
      this.dialog = true;
      this.selectedItem = item;
    },
    getStateString(state) {
      switch (state) {
        case 'ACTIVE':
          return 'Prozess aktiv';
        case 'INCIDENT':
          return 'Fehler aufgetreten';
        case 'COMPLETED':
          return 'Prozess beendet';
        case 'EXTERNALLY_TERMINATED':
          return 'Prozess abgebrochen';
        default:
          return 'Unbekannter Zustand';
      }
    },
    isInWbciProcess(activityList) {
      const activitiesString = activityList
        .map((item) => item.activity)
        .join(' | ');
      const wbciActivities = [
        'Warte auf Vorabstimmungsantwort',
        'Warte auf Portierungstermin',
        'Warte auf Zugangsdatenversand',
        'WBCI Prozess starten' // In case of an unexpected error in the WBCI-process this activity is contained in the activity list
      ];
      return wbciActivities.some((element) =>
        activitiesString.includes(element)
      );
    }
  }
};
</script>

<style scoped lang="scss">
.porting-dashboard::v-deep .process-start {
  width: 150px;
}
.porting-dashboard::v-deep .porting-date {
  width: 150px;
}
.porting-dashboard::v-deep td.process-status {
  width: 85px;
}
.porting-dashboard::v-deep .activity-user {
  max-width: 350px;
}
.porting-dashboard::v-deep .status-unknown {
  width: 30px;
  height: 30px;
  background-color: #6d005e;
  border-radius: 50%;
  box-shadow: 0 0 4px 1px #6d005e;
}
.porting-dashboard::v-deep .status-green {
  width: 30px;
  height: 30px;
  background-color: #80ed99;
  border-radius: 50%;
  box-shadow: 0 0 4px 1px #80ed99;
}
.porting-dashboard::v-deep .status-grey {
  width: 30px;
  height: 30px;
  background-color: var(--goe-fontColor-lighter);
  border-radius: 50%;
  box-shadow: 0 0 4px 1px var(--goe-fontColor-lighter);
}
div.porting-dashboard::v-deep .status-red {
  width: 30px;
  height: 30px;
  background-color: #ff6b6b;
  border-radius: 50%;
  box-shadow: 0 0 4px 1px #ff6b6b;
}
div.porting-dashboard::v-deep .status-light-grey {
  width: 30px;
  height: 30px;
  background-color: #dddd;
  border-radius: 50%;
  box-shadow: 0 0 4px 1px #dddd;
}
div.porting-dashboard::v-deep .icon-wrapper {
  display: flex;
  .activity-col {
    flex-direction: column;
  }
}
div.porting-dashboard::v-deep .button-wrapper {
  display: flex;
  div:first-child .v-btn {
    border-bottom-right-radius: 0px;
    border-top-right-radius: 0px;
  }
  div:last-child .v-btn {
    border-bottom-left-radius: 0px;
    border-top-left-radius: 0px;
  }
  div:not(:first-child):not(:last-child) .v-btn {
    border-radius: 0px;
  }
  div:not(:first-child) .v-btn {
    border-left-style: none;
  }
  .v-btn:hover {
    background-color: var(--goe-fontColor-lighter);
  }
}
.v-tooltip__content {
  pointer-events: auto;
}
.toggle-button-group {
  height: 37px !important;
}
</style>
